import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const VIPiPhonePage = () => (
  <Layout bodyClassName='furry'>
    <SEO title="VIP iPhone Signup" />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Welcome to VIP</h1>
          <p className="lede">We can&rsquo;t wait for you and your pup to try Harper</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--alt stripe--centered" id="furry-form">
      <div className="container details">
        <h2 className="sectionTitle">Free Access to Harper VIPs</h2>
        <p className="prose">We&rsquo;d love to give you a free membership. If you sign up with this form, download the app, and create your account using the same information, you&rsquo;ll have free, lifetime access.</p>
      </div>
      <div className="container">
        <div className="copy">
          <form id="form6" name="form6" className="wufoo topLabel page" acceptCharset="UTF-8" autoComplete="off" encType="multipart/form-data" method="post" noValidate
          action="https://nscramer.wufoo.com/forms/x14eajix1iznvfx/#public">
            <label className="desc" id="title5" htmlFor="Field5">Your name</label>
            <input id="Field5" name="Field5" type="text" className="field text medium input" maxLength="255" tabIndex="1" onKeyUp="" placeholder="Susan" />
        
            <label className="desc" id="title7" htmlFor="Field7">Your email address</label>
            <input id="Field7" name="Field7" type="email" spellCheck="false" className="field text medium input" maxLength="255" tabIndex="2" placeholder="susan@email.com" />

            <div>
              <label className="desc" id="title5" htmlFor="Field3">Your phone number</label>
              <span className="muted">Enter the number for the phone you’ll use to download the app. It&rsquo;s how you&rsquo;ll sign in.</span>
              <input id="Field3" name="Field3" type="tel" className="field text input input--inline" size="3" maxLength="3" tabIndex="2"       onKeyUp="autotab(this, document.getElementById('Field3-1'), this); filterNonnumeric(this);"             onFocus="this.selectionStart = this.selectionEnd = this.value.length;" placeholder="555" required />

              <span className="symbol">&ndash;</span>
              <input id="Field3-1" name="Field3-1" type="tel" className="field text input input--inline" size="3" maxLength="3" tabIndex="3"       onKeyUp="autotab(this, document.getElementById('Field3-2'), document.getElementById('Field3')); filterNonnumeric(this);"       onFocus="this.selectionStart = this.selectionEnd = this.value.length;" placeholder="123" required />
              <span className="symbol">&ndash;</span>
               <input id="Field3-2" name="Field3-2" type="tel" className="field text input input--inline" size="4" maxLength="4" tabIndex="4" onKeyUp="autotab(this, this, document.getElementById('Field3-1')); filterNonnumeric(this);" onFocus="this.selectionStart = this.selectionEnd = this.value.length;" placeholder="4567" required />
            </div>
            <div>
              <input id="saveForm" name="saveForm" className="btn btn--primary btn--block" type="submit" value="Submit" />
              <input type="hidden" id="idstamp" name="idstamp" value="Avunuh3gWWHzZIdaKqnJyUbipeasvbPBAtFXOkhBscE=" />
             </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
);

export default VIPiPhonePage
